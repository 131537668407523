import { ReactNode } from 'react';

import env from '@common/env';

import { client } from '../client/services.gen';

const DCBE_HOST = env('DCBE_HOST');

export default function DigitalCoreBeProvider({ children, token }: { children: ReactNode, token: string | undefined }) {
  client.setConfig({
    baseUrl: DCBE_HOST,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return children;
}
